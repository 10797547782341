import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TerrainlogService {

  constructor(private httpClient: HttpClient) { }

  login(params:any = {}): Observable<any>{
    return this.httpClient.post('/login', params.data);
  }

  logout(params:any = {}): Observable<any>{
    return this.httpClient.post('/logout', params.token);
  }
}
